@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  @apply bg-background text-text font-body;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading;
}